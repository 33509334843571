@use './variables' as var;

@mixin header_img($color: var.$bg_purple) {
  background-color: $color;
  display: flex;
  justify-content: center;
  position: relative;
}

@mixin font($size: var.$general_text, $color: white) {
  font-size: $size;
  color: $color;
}

@mixin button($bg: var.$bg_aqua) {
  @include font($size: var.$title_subtext);
  font-weight: 700;
  background-color: $bg;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  border: none;
  border-radius: 5px;
}

@mixin round_border() {
  border-radius: 15px;
}

@mixin enlarge($num: 1.2) {
  &:hover {
    transform: scale($num);
    transition-duration: 0.5s;
  }
}

@mixin reduce() {
  transition-duration: 0.5s;
}

@mixin flex($direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}

@mixin std_padding() {
  padding: 30px 20px;
}

@mixin vertical_space($margin: 15px) {
  margin: $margin 0;
}

@mixin section_header_mobile() {
  @include font($size: var.$section_header);
  margin-bottom: 40px;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  padding: 20px 40px;
}

@mixin section_header_desktop() {
  border: none;
  align-self: flex-start;
  padding: 0;
  margin: 0;
}

@mixin section_content_mobile() {
  @include flex($direction: column);
  margin-bottom: 60px;
  padding: 10px;
}

@mixin section_content_desktop() {
  @include flex();
  align-items: flex-start;
}

@mixin section_spacer_mobile() {
  margin: 75px 0;
}

@mixin section_spacer_desktop() {
  margin: 150px 0;
}
