@use './variables' as var;
@import './mixins.scss';

.header_container {
  @include flex($direction: column);
  margin: 20px 0;
}

.header_content {
  @include flex($direction: column);
  position: relative;
}

.header_break {
  display: none;
}

.header_name {
  @include font($size: var.$title_name);
  text-align: center;
  margin-bottom: -10px;
  line-height: 1;
}

.header_subtext {
  @include font($size: var.$title_subtext);
  font-family: Poppins, Arial;
  text-align: center;
  opacity: 0.5;
}

.header_description {
  @include vertical_space();
  margin-bottom: clamp(40px, 5vw, 65px);
  opacity: 0.9;
  max-width: 450px;
  text-align: center;
}

.header_button {
  @include button();
  @include vertical_space();
  @include reduce();
  cursor: pointer;

  &:hover {
    @include enlarge();
  }
}

.header_images {
  @include flex();
  justify-content: flex-end;
  margin-top: 40px;
}

.header_img_bg {
  @include round_border();
  margin: 50px 15px 0 15px;
  height: 550px;
}

.header_img_bg1,
.header_img_bg3 {
  background-color: var.$bg_purple;
}

.header_img_bg2 {
  background-color: var.$bg_aqua;
}

.header_img_bg2,
.header_img_bg3 {
  display: none;
}

.header_img {
  transform: translateY(-40px);
}

@media screen and (min-width: 750px) {
  .header_container {
    flex-direction: row;
    height: 800px;
    justify-content: space-between;
  }

  .header_content,
  .header_images {
    width: 50%;
  }

  .header_content {
    align-items: flex-start;
  }

  .header_break {
    display: block;
  }

  .header_name,
  .header_subtext {
    margin-bottom: 20px;
    text-align: left;
  }

  .header_subtext {
    position: absolute;
    top: 0px;
    left: 100px;
    line-height: 1.4;
    width: 220px;
    opacity: 0.75;
  }

  .header_circle {
    position: absolute;
    width: 220px;
    height: 220px;
    top: -80px;
    left: 88px;
    background-color: #3aadc7;
    border-radius: 50%;
    z-index: -1;
    box-shadow: -120px 50px var.$bg_purple;
  }

  .header_description {
    text-align: left;
    max-width: 350px;
  }
}

@media screen and (min-width: 880px) {
  .header_description {
    max-width: 380px;
  }
}

@media screen and (min-width: 1200px) {
  .header_container {
    justify-content: space-between;
  }
  .header_img_bg2 {
    display: block;
    transform: translateY(-80px);
  }

  .header_content {
    align-items: flex-start;
    width: unset;
  }

  .header_images {
    flex-grow: 100;
  }
}

@media screen and (min-width: 1500px) {
  .header_img_bg3 {
    display: block;
  }

  .header_img {
    width: 270px;
  }

  .header_img_bg {
    height: 500px;
  }
}

@media screen and (min-width: 1500px) {
  .header_content {
    width: unset;
  }
}
