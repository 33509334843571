@use './variables' as var;
@import './mixins.scss';

.projects_section_container {
  @include flex($direction: column-reverse);
  @include section_spacer_mobile();
}

.project_left_outer_container {
  @include flex($direction: column);
  @include round_border();
  justify-content: space-between;
  box-sizing: content-box;
  max-width: clamp(330px, 100vw, 550px);
  background-color: var.$bg_aqua;
  padding: clamp(30px, 5vw, 40px) clamp(15px, 4vw, 28px);
}

.project_left_inner_container {
  @include flex($direction: column);
  justify-content: space-between;
  align-items: flex-start;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.project_content {
  @include flex($direction: column);
  align-items: flex-start;
  justify-content: flex-start;
}

.inactive {
  display: none;
}

.project_title {
  @include font($color: var.$bg_blue2, $size: var.$section_header);
  @include vertical_space();
}

.lang_container {
  @include flex();
  @include vertical_space();
  flex-wrap: wrap;
}

.lang_text_container {
  @include button($bg: var.$bg_dark_blue_opacity);
  @include font($size: var.$small_text);
  margin: 0 10px 10px 0;
  padding: 10px;
}

.project_description {
  @include font($size: var.$general_text, $color: var.$bg_blue2);
  @include vertical_space();
}

.project_links_container {
  @include vertical_space();
  justify-self: flex-end;
}

.project_link1,
.project_link2 {
  @include enlarge();
  margin: 0 10px 0 0;
}

.project_selector_container {
  @include flex();
  margin: 10px auto;
}

.project_selector,
.active_project_selector {
  line-height: 1px;
  padding: 8px 8px;
  border-radius: 50%;
  margin: 10px;
  cursor: pointer;
}

.project_selector {
  background-color: var.$bg_purple;

  &:hover {
    @include enlarge(1.5);
  }
}

.active_project_selector {
  background-color: white;
  transform: scale(1.5);
}

.project_right_container {
  @include section_content_mobile;
  margin-top: 50px;
}

.section_title {
  @include section_header_mobile();
}

.project_section_overview {
  @include font($size: var.$general_text);
  @include vertical_space();
}

@media screen and (min-width: 550px) {
  .project_left_outer_container {
    height: 550px;
  }
}

@media screen and (min-width: 800px) {
  .project_section_overview_container {
    @include section_content_desktop();
  }

  .project_section_overview {
    width: 50%;
  }

  .project_section_overview:nth-child(1) {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1100px) {
  .projects_section_container {
    @include section_spacer_desktop;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 75px;
  }

  .project_left_outer_container {
    height: 88%;
  }

  .project_right_container {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 600px;
    height: 100%;
    margin: 0;
  }

  .section_title {
    @include section_header_desktop();
  }

  .project_section_overview_container {
    flex-direction: column;
  }

  .project_section_overview {
    width: 100%;
  }

  .project_section_overview:nth-child(1) {
    margin-right: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
