@use './variables' as var;
@import './mixins.scss';

.nav_container {
  @include flex();
  justify-content: space-between;
}

.nav_items_container {
  @include flex($direction: column);
  align-items: flex-end;
}

.nav_item {
  @include reduce();
  @include font();
  font-weight: bold;
  padding: 5px;

  &:hover {
    @include enlarge();
  }
}

@media screen and (min-width: 750px) {
  .nav_container {
    margin: 10px 0;
  }

  .nav_items_container {
    flex-direction: row;
  }

  .nav_item {
    padding: 0 15px;
  }
}
