@use 'variables' as var;

body {
  width: 100%;
  background-color: var.$bg_blue2;
  font-family: Poppins, Arial;
  min-height: 100vh;
}

.main_content {
  max-width: 1500px;
  margin: auto;
  padding: 20px clamp(10px, 5vw, 50px);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  color: white;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

p {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bebas Neue', Arial;
}
