@use './variables' as var;
@import './mixins.scss';

.contact_section_container {
  @include section_spacer_mobile();
  display: flex;
  justify-content: center;
}

.contact_form_container {
  @include flex($direction: column);
  @include round_border();
  width: 100%;
  overflow: hidden;
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.25);
}

.contact_form_left {
  background-color: var.$bg_purple;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.form_title {
  @include font($size: var.$section_header);
}

.contact_line_break {
  width: 150px;
  height: 2px;
  background-color: var.$bg_blue2;
  margin: 15px 0;
}

.contact_description {
  @include font($size: var.$small_text);
  max-width: 400px;
}

.contact_form {
  @include flex($direction: column);
  border-radius: 0 15px 15px 0;
  align-items: flex-start;
  background-color: var.$bg_form;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.form_line {
  @include flex($direction: column);
  @include font($size: var.$general_text);
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
}

.contact_label {
  margin-bottom: 10px;
  font-weight: 700;
  letter-spacing: 1px;
}

.contact_form_input {
  @include font($size: var.$general_text, $color: black);
  padding-left: 12px;
  line-height: 2.2;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  resize: vertical;
}

.form_button {
  @include button($bg: var.$bg_purple);
  margin-top: 20px;
  cursor: pointer;
}

.recaptcha {
  margin: 30px 0;
}

input:-moz-placeholder,
input::-moz-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder {
  font-family: Poppins, Arial;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: Poppins, Arial;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: Poppins, Arial;
}

@media screen and (min-width: 800px) {
  .contact_form_container {
    display: grid;
    grid-template-columns: clamp(300px, 30vw, 350px) 1fr;
    grid-template-rows: 1fr;
    width: 100%;
  }

  .contact_description {
    max-width: 260px;
  }

  .contact_form_left {
    height: 100%;
    padding-top: 70px;
  }

  .contact_form {
    padding: 40px clamp(30px, 5vw, 70px);
  }

  .contact_line_break {
    margin: 40px 0;
  }
}
