@use './variables' as var;
@import './mixins.scss';

.about_section_container {
  @include flex($direction: column);
  @include section_spacer_mobile();
}

.about_content_outer_container {
  @include section_content_mobile();
}

.about_content {
  @include font($size: var.$general_text);
  @include vertical_space();
}

.about_img_container {
  display: flex;
  justify-content: center;
  width: clamp(300px, 50vw, 600px);
  height: auto;
}

.about_img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 800px) {
  .about_content_inner_container {
    @include section_content_desktop();
  }

  .about_content {
    width: 50%;
  }

  .about_content:nth-child(1) {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .about_section_container {
    @include section_spacer_desktop();
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 75px;
  }

  .about_content_outer_container {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 600px;
    height: 100%;
    margin: 0;
  }

  .about_content_inner_container {
    flex-direction: column;
  }

  .about_content {
    width: 100%;
  }

  .about_img_container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .about_img {
  }
}
